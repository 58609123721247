.desc {
    height: 192px;
    white-space: pre-line;
}

.card-register {
    border-radius: 0%;
}

.card-register .card-header{
    padding-bottom: 16px;
}

.card-register .card-header img {
    position: relative;
    margin-left: unset !important;
    margin-top: unset;
    top: 0;
    width: 100%;
    border-radius: 0;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 35%;
}

.card-register .card-header .card-title {
    text-transform: capitalize;
    margin-left: 5px;
    position: absolute;
    top: 0;
}

.card-register .card-body {
    /* padding-top: 134px; */
    /* padding-bottom: 75px; */
    margin-bottom: -32px;
    min-height: 0;
}

.card-register button h4{
    /* padding-top: 64px; */
    margin: 0;
}