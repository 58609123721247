.landing-main {
    /* margin-bottom: 0; */
    font-size: 6rem;
}

.page-header > .content {
    margin-top: 0%;
    /* text-align: center; */
    /* margin-bottom: 50px; */
}

.fullpage {
    min-height: 100vh;
}

.fullpage-content {
    /* min-height: 100vh; */
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    padding: 0 15px;
    width: 100%;
    max-width: 880px;
}

.fullpage-content h1 {
    color: #ffffff;
    font-weight: 600;
    text-transform: capitalize;
} 

.fullpage-content h3 {
    color: #ffffff;
    /* font-weight: 600; */
    /* text-transform: capitalize; */
} 

.page-header {
    max-height: unset;
}