.Uploading-Area .nav-link {
    margin-right: 0 !important;
}

.Uploading-Area .nav-item {
    width: 100%;
}

.Uploading-Area .tab-space {
    padding-top: 0;
}

.form-control:focus {
    background-color: #171941;
};