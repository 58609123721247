.main .nav-item {
    width: 135px;
}

.main {
    margin-top: 128px;
    min-height: 700px;
}

.main .form-group > label {
    margin-top: 8px;
}

.form-control {
    border-color: unset;
}

textarea.form-control {
    border: 1px solid #cad1d7;
    color: rgba(255, 255, 255, 0.8);
    border-radius: 0.4285rem;
}

textarea.form-control {
    max-height: none;
}

.form-control {
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0s ease-in-out;
};